<!-- eslint-disable no-unused-vars -->
<template>
  <b-card class="text-left">
    <validation-observer ref="formUser">
      <b-form>
        <!-- Código do projeto -->
        <b-row
          class="my-1 m-2 pl-2 pr-2 text-left d-flex justify-content-center"
        >
          <b-col col xs="12" sm="12" md="6" lg="6">
            <b-form-group>
              <label
                for="project_code"
                class="text-center font-weight-bold text-primary"
              >
                Código do projeto :
              </label>
              <validation-provider
                #default="{ errors }"
                :custom-messages="{ required: 'Este campo é obrigatório' }"
                rules="required"
              >
                <BFormInput
                  id="project_code"
                  type="text"
                  v-model="item.project_code"
                ></BFormInput>

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <!-- Nome do projeto -->
        <b-row
          class="my-1 m-2 pl-2 pr-2 text-left d-flex justify-content-center"
        >
          <b-col col xs="12" sm="12" md="6" lg="6">
            <b-form-group>
              <label
                for="project_name"
                class="text-center font-weight-bold text-primary"
              >
                Nome do projeto :
              </label>
              <validation-provider
                #default="{ errors }"
                :custom-messages="{ required: 'Este campo é obrigatório' }"
                rules="required"
              >
                <BFormInput
                  id="project_name"
                  type="text"
                  v-model="item.project_name"
                ></BFormInput>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <!-- Nome do cliente -->
        <b-row
          class="my-1 m-2 pl-2 pr-2 text-left d-flex justify-content-center"
        >
          <b-col col xs="12" sm="12" md="6" lg="6">
            <b-form-group>
              <label
                for="client_name"
                class="text-center font-weight-bold text-primary"
              >
                Nome do cliente :
              </label>
              <validation-provider
                #default="{ errors }"
                :custom-messages="{ required: 'Este campo é obrigatório' }"
                rules="required"
              >
                <BFormInput
                  id="client_name"
                  type="text"
                  v-model="item.client_name"
                ></BFormInput>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <!-- Tipo do projeto -->
        <b-row
          class="my-1 m-2 pl-2 pr-2 text-left d-flex justify-content-center"
        >
          <b-col col xs="12" sm="12" md="6" lg="6">
            <b-form-group>
              <label class="text-center font-weight-bold text-primary"
                >Tipo de Pessoa:</label
              >
              <b-form-radio-group v-model="item.project_type">
                <b-form-radio value="pessoa_fisica">Pessoa Física</b-form-radio>
                <b-form-radio value="pessoa_juridica"
                  >Pessoa Jurídica</b-form-radio
                >
              </b-form-radio-group>
            </b-form-group>
          </b-col>
        </b-row>

        <!-- Documento cliente -->
        <b-row
          class="my-1 m-2 pl-2 pr-2 text-left d-flex justify-content-center"
        >
          <b-col col xs="12" sm="12" md="6" lg="6">
            <b-form-group>
              <label
                for="document"
                class="text-center font-weight-bold text-primary"
              >
                {{ item.project_type === 'pessoa_fisica' ? 'CPF' : 'CNPJ' }}:
              </label>
              <b-form-input
                v-if="item.project_type === 'pessoa_fisica'"
                id="document"
                v-model="item.document"
                placeholder="Digite o CPF"
                v-mask="'###.###.###-##'"
              ></b-form-input>
              <b-form-input
                v-else
                id="document"
                v-model="item.document"
                placeholder="Digite o CNPJ"
                v-mask="'##.###.###/####-##'"
              ></b-form-input>
              <!--  -->
              <!-- <BFormInput
              id="document"
              type="text"
              v-model="item.document"
            ></BFormInput> -->
            </b-form-group>
          </b-col>
        </b-row>

        <!-- Endereço -->
        <b-row
          class="my-1 m-2 pl-2 pr-2 text-left d-flex justify-content-center"
        >
          <b-col col xs="12" sm="12" md="6" lg="6">
            <b-form-group>
              <label for="description" class="font-weight-bold text-primary"
                >Endereço :</label
              >
              <BFormTextarea
                id="description"
                type="textarea"
                v-model="item.address"
              ></BFormTextarea>
            </b-form-group>
          </b-col>
        </b-row>

        <!-- Nome do contato -->
        <b-row
          class="my-1 m-2 pl-2 pr-2 text-left d-flex justify-content-center"
        >
          <b-col col xs="12" sm="12" md="6" lg="6">
            <b-form-group>
              <label
                for="contact_name"
                class="text-center font-weight-bold text-primary"
              >
                Nome do contato :
              </label>
              <BFormInput
                id="contact_name"
                type="text"
                v-model="item.contact_name"
              ></BFormInput>
            </b-form-group>
          </b-col>
        </b-row>

        <!-- Documento do contato -->
        <b-row
          class="my-1 m-2 pl-2 pr-2 text-left d-flex justify-content-center"
        >
          <b-col col xs="12" sm="12" md="6" lg="6">
            <b-form-group>
              <label
                for="contact_document"
                class="text-center font-weight-bold text-primary"
              >
                CPF do contato :
              </label>
              <BFormInput
                id="contact_document"
                type="text"
                v-mask="'###.###.###-##'"
                v-model="item.contact_document"
              ></BFormInput>
            </b-form-group>
          </b-col>
        </b-row>

        <!-- Telefone do contato -->
        <b-row
          class="my-1 m-2 pl-2 pr-2 text-left d-flex justify-content-center"
        >
          <b-col col xs="12" sm="12" md="6" lg="6">
            <b-form-group>
              <label
                for="contact_phone"
                class="text-center font-weight-bold text-primary"
              >
                Telefone do contato :
              </label>
              <BFormInput
                id="contact_phone"
                type="tel"
                v-model="item.contact_phone"
              ></BFormInput>
            </b-form-group>
          </b-col>
        </b-row>

        <!-- Descrição do projeto -->
        <b-row
          class="my-1 m-2 pl-2 pr-2 text-left d-flex justify-content-center"
        >
          <b-col col xs="12" sm="12" md="6" lg="6">
            <b-form-group>
              <label
                for="project_description"
                class="font-weight-bold text-primary"
                >Descrição do projeto :</label
              >
              <BFormTextarea
                id="project_description"
                type="textarea"
                v-model="item.project_description"
              ></BFormTextarea>
            </b-form-group>
          </b-col>
        </b-row>

        <!-- Prazo -->
        <b-row
          class="my-1 m-2 pl-2 pr-2 text-left d-flex justify-content-center"
        >
          <b-col col xs="12" sm="12" md="6" lg="6">
            <b-form-group>
              <label
                for="deadline"
                class="text-center font-weight-bold text-primary"
              >
                Prazo :
              </label>
              <b-input-group>
                <BFormInput
                  id="deadline"
                  type="date"
                  v-model="item.deadline"
                ></BFormInput>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>

        <!-- Data Inicio -->
        <b-row
          class="my-1 m-2 pl-2 pr-2 text-left d-flex justify-content-center"
        >
          <b-col col xs="12" sm="12" md="6" lg="6">
            <b-form-group>
              <label for="date" class="font-weight-bold text-primary"
                >Data de inicio :</label
              >
              <BFormInput
                id="start_date"
                type="date"
                v-model="item.start_date"
              ></BFormInput>
            </b-form-group>
          </b-col>
        </b-row>

        <!-- Data do contrato -->
        <b-row
          class="my-1 m-2 pl-2 pr-2 text-left d-flex justify-content-center"
        >
          <b-col col xs="12" sm="12" md="6" lg="6">
            <b-form-group>
              <label for="contract_date" class="font-weight-bold text-primary"
                >Data do contrato :</label
              >
              <BFormInput
                id="contract_date"
                type="date"
                v-model="item.contract_date"
              ></BFormInput>
            </b-form-group>
          </b-col>
        </b-row>

        <!-- Previsao de conclusao -->
        <b-row
          class="my-1 m-2 pl-2 pr-2 text-left d-flex justify-content-center"
        >
          <b-col col xs="12" sm="12" md="6" lg="6">
            <b-form-group>
              <label for="finish_date" class="font-weight-bold text-primary"
                >Previsão de conclusão :</label
              >
              <BFormInput
                id="finish_date"
                type="date"
                v-model="item.finish_date"
              ></BFormInput>
            </b-form-group>
          </b-col>
        </b-row>

        <!-- Valor -->
        <b-row
          class="my-1 m-2 pl-2 pr-2 text-left d-flex justify-content-center"
        >
          <b-col col xs="12" sm="12" md="6" lg="6">
            <b-form-group>
              <label
                for="project_value"
                class="text-center font-weight-bold text-primary"
              >
                Valor do projeto :
              </label>
              <b-input-group>
                <BFormInput
                  id="project_value"
                  type="text"
                  v-model="item.project_value"
                  v-money="moneyMask"
                >
                </BFormInput>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>

        <!-- Imposto -->
        <b-row
          class="my-1 m-2 pl-2 pr-2 text-left d-flex justify-content-center"
        >
          <b-col col xs="12" sm="12" md="6" lg="6">
            <b-form-group>
              <label
                for="taxes"
                class="text-center font-weight-bold text-primary"
              >
                Imposto :
              </label>
              <b-form-select
                id="taxes"
                v-model="item.tax_id"
                :options="taxes"
                value-field="id"
                text-field="name"
                placeholder="Selecione uma taxa"
              ></b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>

      <!-- Botoes -->
      <div class="justify-center">
        <BButton variant="outline" @click="$router.push('/projetos')"
          >Voltar</BButton
        >
        <BButton @click="saveItem">Salvar</BButton>
      </div>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BCard,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BCol,
  BRow,
  BFormRadioGroup,
  BFormSelect,
  BFormRadio,
  BInputGroup,
} from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '@validations';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { VBTooltip } from 'bootstrap-vue';

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BCard,
    BFormInput,
    BCol,
    BRow,
    BForm,
    BFormGroup,
    BFormTextarea,
    BFormSelect,
    BButton,
    BFormRadioGroup,
    BFormRadio,
    BInputGroup,
    ValidationProvider,
    ValidationObserver,
  },
  data: () => ({
    item: {
      address: '',
      client_name: '',
      contact_document: '',
      contact_name: '',
      contact_phone: '',
      contract_date: '',
      deadline: 20,
      document: '',
      finish_date: '',
      is_archived: false,
      project_code: '',
      project_description: '',
      project_name: '',
      project_type: '',
      project_value: 0,
      start_date: '',
      tax_id: null,
      workspace_id: '',
    },
    taxes: [],
    required,
    moneyMask: {
      prefix: 'R$ ',
      suffix: '',
      thousands: '.',
      decimal: ',',
      precision: 2,
      masked: false, // Deixe como true se quiser que o valor seja exibido mascarado no input
    },
  }),

  created() {
    this.getAllTaxes();
  },

  computed: {},

  methods: {
    getAllTaxes() {
      this.taxes = [];
      this.$store
        .dispatch('getAllTaxes', {
          inactive: false,
          workspace_id: this.$store.getters.currentWorkspace.id,
        })
        .then((resp) => {
          if (resp) {
            resp.map((e) => {
              this.taxes.push({ id: e.id, name: e.tax_name });
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    saveItem() {
      // if (
      //   !this.item.project_code ||
      //   !this.item.project_name ||
      //   !this.item.client_name ||
      //   !this.item.document ||
      //   !this.item.address ||
      //   !this.item.contact_name ||
      //   !this.item.contact_document ||
      //   !this.item.contact_phone ||
      //   !this.item.project_description ||
      //   !this.item.deadline ||
      //   !this.item.start_date ||
      //   !this.item.contract_date ||
      //   !this.item.finish_date ||
      //   !this.item.project_value ||
      //   !this.item.project_type
      // ) {
      //   this.$toast({
      //     component: ToastificationContent,
      //     props: {
      //       title: 'Todos os campos são obrigatórios.',
      //       icon: 'XIcon',
      //       variant: 'danger',
      //     },
      //   });
      //   return;
      // }

      this.$store
        .dispatch('saveProject', {
          ...this.item,
          is_archived: false,
          project_value: this.monetaryToNumber(this.item.project_value),
          workspace_id: this.$store.getters.currentWorkspace.id,
        })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Projeto adicionado com sucesso.',
              icon: 'SuccessIcon',
              variant: 'success',
            },
          });
          this.$router.push({
            name: 'projetos',
          });
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Erro ao adicionar projeto.',
              icon: 'XIcon',
              variant: 'danger',
            },
          });
        });
    },
  },
};
</script>
<style>
.justify-center {
  display: flex;
  justify-content: center;
}
td::before {
  float: none !important;
}
</style>
